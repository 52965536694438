/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import modeIcon from '../images/MODE-white.svg'
//import facebookIcon from '../images/Facebook.svg'
//import instagramIcon from '../images/Instagram.svg'
//import youtubeIcon from '../images/Youtube.svg'
//import linkedinIcon from '../images/Linkedin.svg'
//import pinterestIcon from '../images/Pinterest.svg'
import Header from "./header"
import "./layout.css"
import '../css/global.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className='mt-16 lg:mt-24'>
        <main>{children}</main>

        <footer className='mx-auto pt-6 pb-4 text-center text-sm text-white bg-black'>
          <img src={modeIcon} className='m-0 mx-auto h-5' alt='Mode' />
          <div className='hidden lg:block uppercase mt-4'>
            <Link to="/shop-from-social/" className='inline-block hover:text-gray-500 mx-3 text-xs socialLink'>
              Shop from social
            </Link>
            <Link to='/creators/' className='inline-block hover:text-gray-500 mx-3 text-xs'>
              Creators
            </Link>
            <Link to='/brands/' className='inline-block hover:text-gray-500 mx-3 text-xs'>
              Brands
            </Link>
            <Link to='/about/' className='inline-block hover:text-gray-500 mx-3 text-xs'>
              Company
            </Link>
            <Link to='/contact/' className='inline-block hover:text-gray-500 mx-3 text-xs'>
              Contact
            </Link>
            <Link to='/faq/' className='inline-block hover:text-gray-500 mx-3 text-xs'>
              FAQ
            </Link>
          </div>
          {/*
          <div className='mt-4 flex flex-row justify-center'>
            <img src={facebookIcon} className='my-0 mx-3 w-3' alt='Facebook' />
            <img src={instagramIcon} className='my-0 mx-3 w-4' alt='Instagram' />
            <img src={youtubeIcon} className='my-0 mx-3 w-4' alt='Youtube' />
            <img src={linkedinIcon} className='my-0 mx-3 w-4' alt='Linkedin' />
            <img src={pinterestIcon} className='my-0 mx-3 w-4' alt='Pinterest' />
          </div>
          */}
          <div className='mt-4 text-xs'>
            <span className='mx-4 hidden md:inline-block'>
              © MODE {new Date().getFullYear()}
            </span>
            <Link to='/privacy/' className='mx-4 underline'>
              Privacy & Cookie Policies
            </Link>
            <Link to='/terms/' className='mx-4 underline'>
              Terms and conditions
            </Link>
          </div>
          <div className='mt-4 block md:hidden'>
            © MODE {new Date().getFullYear()}
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
