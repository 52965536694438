import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styles from "./header.module.scss"
import modeIcon from "../images/MODE.svg"

const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)

  const menuClassName = showMenu ? "block" : "hidden"
  const menuItemClassName =
    "uppercase block lg:inline-block mt-8 lg:mt-0 lg:ml-4 hover:text-gray-500"

  return (
    <header className="w-full fixed z-10 top-0 bg-white">
      <div className="container mx-auto">
        <nav className="flex flex-col lg:flex-row items-center justify-between flex-wrap p-4 lg:p-8">
          <div className={`flex ${styles.logoSection}`}>
            <div className="absolute block lg:hidden">
              <button
                onClick={() => setShowMenu(!showMenu)}
                className="flex items-center py-1 text-teal-200 border-teal-400 hover:text-white hover:border-white"
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div className="block mx-auto">
              <Link to="/">
                <img
                  src={modeIcon}
                  className={`mb-0 ${styles.icon}`}
                  alt={siteTitle}
                />
              </Link>
            </div>
          </div>

          <div className={`${menuClassName} lg:block flex-grow`}>
            <div className="text-black text-sm text-center lg:text-right">
              <Link to="/shop-from-social/" className={`${menuItemClassName} socialLink`}>
                Shop from social
              </Link>
              <Link to="/creators/" className={menuItemClassName}>
                Creators
              </Link>
              <Link to="/brands/" className={menuItemClassName}>
                Brands
              </Link>
              <Link to="/about/" className={menuItemClassName}>
                Company
              </Link>
              <Link to="/contact/" className={menuItemClassName}>
                Contact
              </Link>
              <Link to="/faq/" className={menuItemClassName}>
                FAQ
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
